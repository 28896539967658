import { Injectable, TemplateRef } from '@angular/core';
import { Size } from '@interacta-shared/ui';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { first } from 'rxjs/operators';

interface ConfirmDialogOpenParams {
    title: string;
    description?: string;
    cancel?: string;
    confirm?: string;
    size?: Size;
    template?: TemplateRef<any> | null;
    translationVars?: object;
}

export interface ConfirmDialogState {
    isOpen: boolean;
    content: Required<ConfirmDialogOpenParams>;
}

@Injectable({
    providedIn: 'root',
})
export class ConfirmDialogService {
    private state$ = new BehaviorSubject<ConfirmDialogState>({
        isOpen: false,
        content: {
            title: '',
            description: '',
            cancel: '',
            confirm: '',
            size: 'extra-small',
            template: null,
            translationVars: {},
        },
    });
    private close$ = new Subject<boolean>();

    open({
        title,
        description = '',
        cancel = 'SHARED.CONFIRM_DIALOG.DEFAULT_CANCEL',
        confirm = 'SHARED.CONFIRM_DIALOG.DEFAULT_CONFIRM',
        size = 'small',
        template = null,
        translationVars = {},
    }: ConfirmDialogOpenParams): Observable<boolean> {
        return new Observable((subscriber) => {
            this.state$.next({
                isOpen: true,
                content: {
                    title,
                    description,
                    cancel,
                    confirm,
                    size,
                    template,
                    translationVars,
                },
            });

            return this.close$.pipe(first()).subscribe((confirm) => {
                this.state$.next({ ...this.state$.value, isOpen: false });

                subscriber.next(confirm);
                subscriber.complete();
            });
        });
    }

    close(confirm: boolean): void {
        this.close$.next(confirm);
    }

    getState(): Observable<ConfirmDialogState> {
        return this.state$.asObservable();
    }
}
